<template>
  <a-form-model
    ref="form"
    :label-col="{
      span: 6,
      style: {
        textAlign: 'left'
      }
    }"
    :model="form"
    :rules="rules"
    :wrapper-col="{
      span: 18
    }"
  >
    <a-divider orientation="left">登录设置</a-divider>
    <a-form-model-item prop="tokenExpiresIn">
      <question-icon
        slot="label"
        title="登录过期时间"
        description="超过时间需要重新登录"
      ></question-icon>
      <a-input
        v-model="form.tokenExpiresIn"
        suffix="时"
        type="number"
        :min="1"
      ></a-input>
    </a-form-model-item>
    <a-form-model-item prop="loginFailedTimes">
      <question-icon
        slot="label"
        title="登录失败次数"
        description="允许登录失败的次数"
      ></question-icon>
      <a-input
        v-model="form.loginFailedTimes"
        suffix="次"
        type="number"
        :max="10"
        :min="1"
      ></a-input>
    </a-form-model-item>
    <a-form-model-item prop="loginFailedInterval">
      <question-icon
        slot="label"
        title="登录失败间隔"
        description="超过登录失败次数需要等待的时间"
      ></question-icon>
      <a-input
        v-model="form.loginFailedInterval"
        suffix="分"
        type="number"
        :max="43200"
        :min="1"
      ></a-input>
    </a-form-model-item>
    <a-divider orientation="left">密码设置</a-divider>
    <a-form-model-item label="密码最小位数" prop="passwordMinLength">
      <a-input
        v-model="form.passwordMinLength"
        suffix="位"
        type="number"
        :max="16"
        :min="8"
      ></a-input>
    </a-form-model-item>
    <a-form-model-item prop="passwordExpiresIn">
      <question-icon
        slot="label"
        title="密码过期时间"
        description="密码过期时间为 0 则永不过期"
      ></question-icon>
      <a-input
        v-model="form.passwordExpiresIn"
        suffix="天"
        type="number"
        :min="0"
      ></a-input>
    </a-form-model-item>
    <a-form-model-item label="密码包含大写字母" prop="passwordUpperRequired">
      <a-switch v-model="form.passwordUpperRequired"></a-switch>
    </a-form-model-item>
    <a-form-model-item label="密码包含小写字母" prop="passwordLowerRequired">
      <a-switch v-model="form.passwordLowerRequired"></a-switch>
    </a-form-model-item>
    <a-form-model-item label="密码包含数字" prop="passwordDigitRequired">
      <a-switch v-model="form.passwordDigitRequired"></a-switch>
    </a-form-model-item>
    <a-form-model-item label="密码包含特殊字符" prop="passwordPunctuationRequired">
      <a-switch v-model="form.passwordPunctuationRequired"></a-switch>
    </a-form-model-item>
    <a-form-model-item
      :wrapper-col="{ span: 18, offset: 6 }"
      style="margin-bottom: 0"
    >
      <a-space size="middle">
        <a-button :loading="loading" type="primary" @click="ok" v-permission="{action: 'base.setting.update', effect: 'disabled'}">更新</a-button>
      </a-space>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { getSecuritySetting, updateSecuritySetting } from '@/api/setting'
import QuestionIcon from '@/components/icon/QuestionIcon'

export default {
  name: 'SecuritySetting',
  components: {
    QuestionIcon
  },
  data () {
    return {
      form: {
        tokenExpiresIn: 1,
        loginFailedTimes: 5,
        loginFailedInterval: 30,
        passwordMinLength: 8,
        passwordExpiresIn: 90,
        passwordUpperRequired: true,
        passwordLowerRequired: true,
        passwordDigitRequired: true,
        passwordPunctuationRequired: true
      },
      rules: {
        tokenExpiresIn: [{
          message: '请输入登录过期时间',
          required: true,
          trigger: 'blur'
        }],
        loginFailedTimes: [{
          message: '请输入登录失败次数',
          required: true,
          trigger: 'blur'
        }],
        loginFailedInterval: [{
          message: '请输入登录失败间隔',
          required: true,
          trigger: 'blur'
        }],
        passwordMinLength: [{
          message: '请输入密码最小位数',
          required: true,
          trigger: 'blur'
        }],
        passwordExpiresIn: [{
          message: '请输入密码过期时间',
          required: true,
          trigger: 'blur'
        }]
      },
      loading: false
    }
  },
  mounted () {
    getSecuritySetting().then(res => {
      const data = res.data
      this.form.tokenExpiresIn = data.token_expires_in
      this.form.loginFailedTimes = data.login_failed_times
      this.form.loginFailedInterval = data.login_failed_interval
      this.form.passwordMinLength = data.password_min_length
      this.form.passwordExpiresIn = data.password_expires_in
      this.form.passwordUpperRequired = data.password_upper_required
      this.form.passwordLowerRequired = data.password_lower_required
      this.form.passwordDigitRequired = data.password_digit_required
      this.form.passwordPunctuationRequired = data.password_punctuation_required
    })
  },
  methods: {
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            token_expires_in: this.form.tokenExpiresIn,
            login_failed_times: this.form.loginFailedTimes,
            login_failed_interval: this.form.loginFailedInterval,
            password_min_length: this.form.passwordMinLength,
            password_expires_in: this.form.passwordExpiresIn,
            password_upper_required: this.form.passwordUpperRequired,
            password_lower_required: this.form.passwordLowerRequired,
            password_digit_required: this.form.passwordDigitRequired,
            password_punctuation_required: this.form.passwordPunctuationRequired
          }
          this.loading = true
          updateSecuritySetting(params)
            .then(res => {
              this.$message.success(res.message)
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
