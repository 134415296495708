var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form-model',{ref:"form",attrs:{"label-col":{
    span: 6,
    style: {
      textAlign: 'left'
    }
  },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{
    span: 18
  }}},[_c('a-form-model-item',{attrs:{"label":"应用名称"}},[_c('a-input',{attrs:{"disabled":true},model:{value:(_vm.detail.app),callback:function ($$v) {_vm.$set(_vm.detail, "app", $$v)},expression:"detail.app"}})],1),_c('a-form-model-item',{attrs:{"label":"应用版本"}},[_c('a-input',{attrs:{"disabled":true},model:{value:(_vm.detail.version),callback:function ($$v) {_vm.$set(_vm.detail, "version", $$v)},expression:"detail.version"}})],1),_c('a-form-model-item',{attrs:{"label":"授权时间"}},[_c('a-input',{attrs:{"disabled":true},model:{value:(_vm.detail.created_at),callback:function ($$v) {_vm.$set(_vm.detail, "created_at", $$v)},expression:"detail.created_at"}})],1),_c('a-form-model-item',{attrs:{"label":"到期时间"}},[_c('a-input',{attrs:{"disabled":true},model:{value:(_vm.detail.expires_at),callback:function ($$v) {_vm.$set(_vm.detail, "expires_at", $$v)},expression:"detail.expires_at"}})],1),_c('a-form-model-item',{attrs:{"label":"授权组织"}},[_c('a-input',{attrs:{"disabled":true},model:{value:(_vm.detail.org),callback:function ($$v) {_vm.$set(_vm.detail, "org", $$v)},expression:"detail.org"}})],1),_c('a-form-model-item',{attrs:{"label":"授权文件","prop":"fileList"}},[_c('a-upload',{attrs:{"accept":".lic","before-upload":_vm.beforeUpload,"file-list":_vm.form.fileList}},[_c('a-button',{attrs:{"type":"link"}},[_c('a-icon',{attrs:{"type":"upload"}}),_c('span',[_vm._v("上传")])],1)],1)],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"wrapper-col":{ span: 18, offset: 6 }}},[_c('a-space',{attrs:{"size":"middle"}},[_c('a-button',{directives:[{name:"permission",rawName:"v-permission",value:({action: 'base.setting.update', effect: 'disabled'}),expression:"{action: 'base.setting.update', effect: 'disabled'}"}],attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.ok}},[_vm._v("更新")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }