<template>
  <a-modal
    :confirm-loading="loading"
    title="测试邮箱设置"
    :visible="visible"
    :width="720"
    @cancel="reset"
    @ok="ok"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item
        label="测试邮箱地址"
        prop="to"
        style="margin-bottom: 0"
      >
        <a-input
          v-model="form.to"
          :max-length="128"
          placeholder="请输入测试邮箱地址"
        ></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { testEmailSetting } from '@/api/setting'

export default {
  name: 'TestModal',
  data () {
    return {
      loading: false,
      visible: false,
      form: {},
      rules: {
        to: [
          {
            message: '请输入测试邮箱地址',
            required: true,
            trigger: 'blur'
          }
        ]
      },
      setting: {}
    }
  },
  methods: {
    show (setting) {
      this.setting = setting
      this.visible = true
    },
    reset () {
      this.form = {}
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          testEmailSetting({ ...this.setting, ...this.form })
            .then(res => {
              this.$message.success(res.message)
              this.reset()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
