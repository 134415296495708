var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"confirm-loading":_vm.loading,"title":"测试邮箱设置","visible":_vm.visible,"width":720},on:{"cancel":_vm.reset,"ok":_vm.ok}},[_c('a-form-model',{ref:"form",attrs:{"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{
      span: 20
    }}},[_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"label":"测试邮箱地址","prop":"to"}},[_c('a-input',{attrs:{"max-length":128,"placeholder":"请输入测试邮箱地址"},model:{value:(_vm.form.to),callback:function ($$v) {_vm.$set(_vm.form, "to", $$v)},expression:"form.to"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }