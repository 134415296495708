var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form-model',{ref:"form",attrs:{"label-col":{
      span: 6,
      style: {
        textAlign: 'left'
      }
    },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{ span: 18 }}},[_c('a-form-model-item',{attrs:{"label":"SMTP 主机","prop":"host"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.host),callback:function ($$v) {_vm.$set(_vm.form, "host", $$v)},expression:"form.host"}})],1),_c('a-form-model-item',{attrs:{"label":"SMTP 账号","prop":"user"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.user),callback:function ($$v) {_vm.$set(_vm.form, "user", $$v)},expression:"form.user"}})],1),_c('a-form-model-item',{attrs:{"label":"SMTP 密码","prop":"password"}},[_c('a-input-password',{attrs:{"autocomplete":"new-password","max-length":128,"visibility-toggle":false},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('a-form-model-item',{attrs:{"label":"使用 SSL","prop":"useSsl"}},[_c('a-switch',{model:{value:(_vm.form.useSsl),callback:function ($$v) {_vm.$set(_vm.form, "useSsl", $$v)},expression:"form.useSsl"}})],1),_c('a-form-model-item',{attrs:{"label":"使用 TLS","prop":"useTls"}},[_c('a-switch',{model:{value:(_vm.form.useTls),callback:function ($$v) {_vm.$set(_vm.form, "useTls", $$v)},expression:"form.useTls"}})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"wrapper-col":{ span: 18, offset: 6 }}},[_c('a-space',{attrs:{"size":"middle"}},[_c('a-button',{directives:[{name:"permission",rawName:"v-permission",value:({
            action: 'base.setting.update',
            effect: 'disabled'
          }),expression:"{\n            action: 'base.setting.update',\n            effect: 'disabled'\n          }"}],attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.ok}},[_vm._v(" 更新 ")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")]),_c('a-button',{directives:[{name:"permission",rawName:"v-permission",value:({
            action: 'base.setting.update',
            effect: 'disabled'
          }),expression:"{\n            action: 'base.setting.update',\n            effect: 'disabled'\n          }"}],staticStyle:{"background":"#ffa040","border-color":"#ffa040"},attrs:{"type":"primary"},on:{"click":_vm.test}},[_vm._v(" 测试 ")])],1)],1)],1),_c('test-modal',{ref:"testModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }