<template>
  <div class="system-container">
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <a-space slot="title" size="middle">
        <a-icon type="setting"></a-icon>
        <span>系统设置</span>
      </a-space>

      <span>系统设置包含邮箱、安全、授权等设置。</span>
    </a-page-header>

    <a-card :body-style="{ paddingTop: '8px' }" :bordered="false">
      <a-tabs>
        <a-tab-pane key="email" tab="邮箱设置">
          <a-row>
            <a-col :xl="12" :md="24">
              <a-alert
                message="用于发送告警邮件、新用户邮件等。"
                :show-icon="true"
                type="info"
                style="margin-bottom: 24px"
              ></a-alert>

              <email-setting></email-setting>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="security" tab="安全设置">
          <a-row>
            <a-col :xl="12" :md="24">
              <security-setting></security-setting>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="license" tab="授权设置">
          <a-row>
            <a-col :xl="12" :md="24">
              <license-setting></license-setting>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </div>
</template>

<script>
import EmailSetting from './modules/EmailSetting/index.vue'
import LicenseSetting from './modules/LicenseSetting.vue'
import SecuritySetting from './modules/SecuritySetting.vue'

export default {
  name: 'System',
  components: {
    EmailSetting,
    LicenseSetting,
    SecuritySetting
  }
}
</script>
