<template>
  <div>
    <a-form-model
      ref="form"
      :label-col="{
        span: 6,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-model-item label="SMTP 主机" prop="host">
        <a-input v-model="form.host" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="SMTP 账号" prop="user">
        <a-input v-model="form.user" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="SMTP 密码" prop="password">
        <a-input-password
          v-model="form.password"
          autocomplete="new-password"
          :max-length="128"
          :visibility-toggle="false"
        ></a-input-password>
      </a-form-model-item>
      <a-form-model-item label="使用 SSL" prop="useSsl">
        <a-switch v-model="form.useSsl"></a-switch>
      </a-form-model-item>
      <a-form-model-item label="使用 TLS" prop="useTls">
        <a-switch v-model="form.useTls"></a-switch>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 18, offset: 6 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button
            :loading="loading"
            type="primary"
            @click="ok"
            v-permission="{
              action: 'base.setting.update',
              effect: 'disabled'
            }"
          >
            更新
          </a-button>
          <a-button @click="reset">重置</a-button>
          <a-button
            type="primary"
            @click="test"
            style="background: #ffa040; border-color: #ffa040"
            v-permission="{
              action: 'base.setting.update',
              effect: 'disabled'
            }"
          >
            测试
          </a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
    <test-modal ref="testModal"></test-modal>
  </div>
</template>

<script>
import { getEmailSetting, updateEmailSetting } from '@/api/setting'
import TestModal from './modules/TestModal'

export default {
  name: 'EmailSetting',
  components: {
    TestModal
  },
  data () {
    return {
      form: {
        host: '',
        user: '',
        password: '',
        useSsl: false,
        useTls: false
      },
      rules: {
        host: [
          {
            message: '请输入 SMTP 主机',
            required: true,
            trigger: 'blur'
          }
        ],
        user: [
          {
            message: '请输入 SMTP 账号',
            required: true,
            trigger: 'blur'
          }
        ]
      },
      loading: false
    }
  },
  mounted () {
    getEmailSetting().then(res => {
      const data = res.data
      this.form.host = data.host
      this.form.user = data.user
      this.form.useSsl = data.use_ssl
      this.form.useTls = data.use_tls
    })
  },
  methods: {
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            host: this.form.host,
            user: this.form.user,
            use_ssl: this.form.useSsl,
            use_tls: this.form.useTls
          }
          if (this.form.password) params.password = this.form.password
          this.loading = true
          updateEmailSetting(params)
            .then(res => {
              this.$message.success(res.message)
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    test () {
      this.$refs.testModal.show({
        host: this.form.host,
        user: this.form.user,
        use_ssl: this.form.useSsl,
        use_tls: this.form.useTls
      })
    },
    reset () {
      this.form = {
        host: '',
        user: '',
        password: '',
        useSsl: false,
        useTls: false
      }
      this.$refs.form.resetFields()
    }
  }
}
</script>
