<template>
  <a-form-model
    ref="form"
    :label-col="{
      span: 6,
      style: {
        textAlign: 'left'
      }
    }"
    :model="form"
    :rules="rules"
    :wrapper-col="{
      span: 18
    }"
  >
    <a-form-model-item label="应用名称">
      <a-input v-model="detail.app" :disabled="true"></a-input>
    </a-form-model-item>
    <a-form-model-item label="应用版本">
      <a-input v-model="detail.version" :disabled="true"></a-input>
    </a-form-model-item>
    <a-form-model-item label="授权时间">
      <a-input v-model="detail.created_at" :disabled="true"></a-input>
    </a-form-model-item>
    <a-form-model-item label="到期时间">
      <a-input v-model="detail.expires_at" :disabled="true"></a-input>
    </a-form-model-item>
    <a-form-model-item label="授权组织">
      <a-input v-model="detail.org" :disabled="true"></a-input>
    </a-form-model-item>
    <a-form-model-item label="授权文件" prop="fileList">
      <a-upload
        accept=".lic"
        :before-upload="beforeUpload"
        :file-list="form.fileList"
      >
        <a-button type="link">
          <a-icon type="upload"></a-icon>
          <span>上传</span>
        </a-button>
      </a-upload>
    </a-form-model-item>
    <a-form-model-item
      :wrapper-col="{ span: 18, offset: 6 }"
      style="margin-bottom: 0"
    >
      <a-space size="middle">
        <a-button :loading="loading" type="primary" @click="ok" v-permission="{action: 'base.setting.update', effect: 'disabled'}">更新</a-button>
      </a-space>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { getLicense, updateLicense } from '@/api/setting'

export default {
  name: 'LicenseSetting',
  data () {
    return {
      detail: {
        app: '',
        version: '',
        created_at: '',
        expires_at: '',
        org: ''
      },
      form: {
        fileList: []
      },
      rules: {
        fileList: [
          {
            message: '请上传授权文件',
            required: true,
            trigger: 'blur'
          }
        ]
      },
      loading: false
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getLicense().then(res => {
        this.detail = res.data
      })
    },
    beforeUpload (file) {
      this.form.fileList.push(file)
      return false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = new FormData()
          params.append('file', this.form.fileList[0])
          updateLicense(params)
            .then(res => {
              this.$message.success(res.message)
              this.$refs.form.resetFields()
              this.fetch()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
